<template>
  <div>
     <!-- 修改密码 -->
    <Dialog
      width="600px"
      :title="'修改密码'"
      :visible="newVisiblepass"
      @close="closeDialog">
      <div class="cgpasd-wrap">
         <p>
          12~20位，必须包含大写字母、小写字母、数字、特殊字符（ . : / _ - @ ）
        </p>
        <el-form
        status-icon
        size="small"
        ref="ruleForm"
        class="cgpasd-form"
        label-position="left"
        label-width="110px"
        :rules="rules"
        :model="form">
          <el-form-item v-if="!firstLogin" label="旧密码：" prop="oldPassword">
            <el-input v-model="form.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-input v-model="form.newPassword" autocomplete="off" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码：" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" autocomplete="off" type="password"></el-input>
          </el-form-item>
        </el-form>
        <el-row  type="flex" justify="end">
          <el-button @click="cacel" size="small">取 消</el-button>
          <el-button type="success" @click="submitModification" size="small">确 定</el-button>
        </el-row>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { resetUserPass } from '@/api/modules/system'
import { firstPassword } from '@/api/modules/login'
import { clearLocalStorage } from '@/utils/storage.js'
import { exJSEncrypt } from '@/utils/rsa'
import Dialog from '@/components/Dialog'
export default {
  name: 'ChangePassword',
  props: {
    visiblepass: {
      type: Boolean,
      default: false
    },
    firstLogin: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Dialog
  },
  data() {
    var confirmPasswordRule = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      newVisiblepass: false,
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码' },
          { pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[.:/_\-@ ])([a-zA-Z\d.:/_\-@ ]){12,20}$/, message: '12~20位，必须包含大写字母、小写字母、数字、特殊字符（ . : / _ - @ ）' }
        ],
        confirmPassword: [
          { validator: confirmPasswordRule },
          { required: true, message: '请再次输入新密码' },
        ]
      },
    }
  },
  watch: {
    visiblepass(newValue) {
      console.log('visiblepass: ', newValue)
      this.newVisiblepass = newValue
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:visiblepass', false)
      this.$refs.ruleForm.resetFields()
      this.isFirstLogin()
    },
    // 修改密码
    submitModification() {
      // console.log('submitModification: ', this.$store.state.publicKey)
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        const password = exJSEncrypt(this.$store.state.publicKey, this.form.newPassword).data
        if (this.firstLogin) {
          this.handleChangePassword(firstPassword, { newPassword: password })
        } else {
          const oldPassword = exJSEncrypt(this.$store.state.publicKey, this.form.oldPassword).data
          this.handleChangePassword(resetUserPass, { oldPassword, newPassword: password, confirmPassword: password })
        }
      })
    },
    handleChangePassword(fn, data) {
      fn(data).then(res => {
        if (res.code === '000000000') {
          this.newVisiblepass = false
          this.$message.success('修改密码成功,请重新登录')
          clearLocalStorage()
          this.$router.replace('/login')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    cacel() {
      this.isFirstLogin()
    },
    isFirstLogin() {
      this.newVisiblepass = false
      if (this.firstLogin) {
        clearLocalStorage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cgpasd-wrap{
  p{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A2A3A5;
    line-height: 20px;
  }
  .cgpasd-form{
    padding: 30px 20px 64px 20px;
    & > * + * {
      margin-top: 30px;
    }
   ::v-deep .el-input{
      // width: 280px;
      &__suffix{
        color:#85CF26 ;
      }
    }
  }
  ::v-deep .el-input__suffix .el-icon-circle-check{
    font-size: 20px;
  }
  ::v-deep .el-input__suffix .el-icon-circle-close{
    font-size: 20px;
  }
  // 去除记住密码背景颜色
  ::v-deep input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #666666 !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }
  ::v-deep input {
    background-color: transparent;
  }
}
</style>

import { axios1 } from '../instance'

/* 系统管理 */

/**
 * 用户新增
 * @param {object} data
 * @param {string} data.roleName 角色
 * @param {string} data.userName 用户名
 * @param {string} data.phone 手机号
 * @param {string} data.mail 邮箱
 * @return {Promise}
 */
export const createUser = data => {
  return axios1.postByJson('/user/create', data)
}

/**
 * 创建用户（重复处理）
 * @param {object} data
 * @param {string} data.roleName 角色
 * @param {string} data.userName 用户名
 * @param {string} data.phone 手机号
 * @param {string} data.mail 邮箱
 * @return {Promise}
 */
export const addUserRepeat = data => {
  return axios1.postByJson('/user/add', data)
}

/**
 * 用户编辑
 * @param {object} data
 * @param {string} data.roleName 角色
 * @param {string} data.mail 邮箱
 * @param {string} data.uicId  用户uicId
 * @return {Promise}
 */
export const updateUser = data => {
  return axios1.postByJson('/user/update', data)
}

/**
 * 角色查询
 * @param {object} data
 * @param {number} data.pageNum 分页
 * @param {number} data.pageSize 页量
 * @return {Promise}
 */
export const searchUserList = data => {
  return axios1.postByJson('/role/search', data)
}

/**
 * 用户重置密码
 * @param {object} data
 * @param {string} data.oldPassword 旧密码
 * @param {string} data.newPassword 新密码
 * @param {string} data.confirmPassword 确认新密码
 * @param {string} data.userId 用户ID
 * @return {Promise}
 */
export const resetUserPass = data => {
  return axios1.postByJson('/user/password/reset', data)
}

/**
 * 用户启用
 * @param {object} data
 * @param {string} data.userId 用户ID
 * @return {Promise}
 */
export const enabledUser = data => {
  return axios1.postByJson('/user/enable', data)
}

/**
 * 用户禁用
 * @param {object} data
 * @param {string} data.userId 用户ID
 * @return {Promise}
 */
export const disableUser = data => {
  return axios1.postByJson('/user/disable', data)
}

/**
 * 用户查询
 * @param {object} data
 * @param {string} data.pageNum 当前页
 * @param {string} data.pageSize 页大小
 * @param {string} data.searchUserName 用户名称
 * @param {string} data.searchPhone 用户手机号
 * @param {string} data.searchRoleId 角色
 * @param {string} data.searchStatus 状态
 * @return {Promise}
 */
export const searchUser = data => {
  return axios1.postByJson('/user/search', data)
}
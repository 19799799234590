import { JSEncrypt } from 'jsencrypt'
const developmentStr = ''
const productionStr = ''
const isProduction = process.env.NODE_ENV === 'production'
/**
 * 数据加密
 * @param {string} [pubstr='']
 * @param {string} [paddstr='']
 * @return {*}
 */
export const exJSEncrypt = (pubstr = '', paddstr = '') => {
  const encrypt = new JSEncrypt()
  const pubkey = isProduction ? pubstr || productionStr : pubstr || developmentStr
  encrypt.setPublicKey(pubkey)
  const data = encrypt.encrypt(paddstr)
  return { data, paddstr }
}
